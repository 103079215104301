import React from "react"
import { Link } from "gatsby"

const renderPreviousLink = ({ hasPreviousPage, currentPage, archivePath }) => {
  let previousLink = null

  if (2 === currentPage) {
    previousLink = archivePath
  } else if (2 < currentPage) {
    previousLink = `${archivePath}page/${currentPage - 1}`
  }

  if (hasPreviousPage) {
    return (
      <li className="page-item">
        <Link className={"page-link"} to={previousLink} aria-label={"Previous"}>
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 30 30" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 6 L11 15 L19 24" /></svg>
          <span className="page-prev-text">
            &nbsp;
            <span className="nav-narrow">Prev</span>
          </span>
        </Link>
      </li>
    )
  } else {
    return (
      <li className="page-item disabled">
        <span className={"page-link"} aria-hidden="true" aria-label={"Previous"}>
          <svg aria-hidden="true"xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 30 30" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 6 L11 15 L19 24" /></svg>
          <span className="page-prev-text">
            &nbsp;
            <span className="nav-narrow">Prev</span>
          </span>
        </span>
      </li>
    )
  }
}

const renderNextLink = ({ hasNextPage, currentPage, archivePath }) => {
  if (hasNextPage) {
    return (
      <li className="page-item">
        <Link
          className={"page-link"}
          to={`${archivePath}page/${currentPage + 1}`}
          aria-label={"Next"}
        >
          <span className="page-next-text">
            <span className="nav-narrow">Next</span>
            &nbsp;
          </span>
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 30 30" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 6 L19 15 L11 24" /></svg>
        </Link>
      </li>
    )
  } else {
    return (
      <li className="page-item disabled">
        <span className={"page-link"} aria-hidden="true" aria-label={"Next"}>
          <span className="page-next-text">
            <span className="nav-narrow">Next</span>
            &nbsp;
          </span>
          <svg aria-hidden="true"xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 30 30" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 6 L19 15 L11 24" /></svg>
        </span>
      </li>
    )
  }
}

const renderPagesInBetween = ({ currentPage, pageCount, archivePath }) => {
  const pageNumber = (page, isCurrent, isFirst) => {
    if (isCurrent) {
      return {
        tag: "span",
        active: "active",
        children: page,
        className: "page-link",
      }
    }

    const to = isFirst ? archivePath : `${archivePath}page/${page}`

    return {
      tag: Link,
      children: page,
      to: to,
      className: "page-link",
    }
  }

  const dots = {
    tag: "span",
    disabled: "disabled",
    children: "…",
    className: "page-link dots",
  }

  let elementsToRender = []

  if (pageCount > 5) {
    // current is 1 - 3: show all on left side and dots for right
    if (currentPage < 4) {
      for (let i = 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
      elementsToRender.push(dots) // dots in between
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
    // if on of the last 3
    else if (currentPage >= pageCount - 2) {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i < pageCount + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
    } else {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }

      elementsToRender.push(dots)
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
  } else {
    for (let i = 1; i < pageCount + 1; i++) {
      elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
    }
  }

  return elementsToRender.map(({ tag: Tag, active, disabled, children, ...props }, index) => (
    <li
      key={index}
      className={
        "page-item " +
        (active ? active : "") +
        (disabled ? disabled : "")
      }
    >
      <Tag {...props}>
        {children}
      </Tag>
    </li>
  ))
}

const ArchivePagination = ({
  hasPreviousPage,
  hasNextPage,
  currentPage,
  pageCount,
  archivePath,
}) => {
  if (pageCount <= 1) {
    return null
  }

  return (
    <div className="pagination-wrapper">
      <nav role="navigation" aria-label="Category pages">
        <h2 className="screen-reader-text">Category page navigation</h2>
        <ul className="pagination justify-content-center">
          {renderPreviousLink({ hasPreviousPage, currentPage, archivePath })}
          {renderPagesInBetween({ currentPage, pageCount, archivePath })}
          {renderNextLink({ hasNextPage, currentPage, archivePath })}
        </ul>
      </nav>
    </div>
  )
}

export default ArchivePagination
