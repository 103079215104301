import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageSection from '../components/page-section'
import HeroCategoryVideoCard from '../components/hero-category-video-card'
import VideoCard from '../components/video-card'
import ArchivePagination from "../components/archive-pagination"

const VideoCategoryArchive = (props) => {
  const {
    data: {
      allPosts: { nodes, pageInfo },
      featuredPosts,
    },
    pageContext: {
      archiveType,
      archivePath,
      archiveName,
      archiveDescription,
      archiveImage,
      archiveBgColour,
      archiveFgText,
      uri
    },
  } = props

  let heroVideo, allVideos

  if ( featuredPosts?.nodes && featuredPosts.nodes.length > 0 ) {
    // display featured videos in the hero position, on the first page only
    if ( pageInfo.currentPage === 1 ) {
      heroVideo = featuredPosts.nodes.slice( 0, 1 )[0] // newest (by publish date) only for now
    }
    if ( nodes ) {
      allVideos = nodes
    }
  } else {
    // display the newest video (by publish date) in the hero position, on the first page only
    if ( nodes ) {
      if ( pageInfo.currentPage === 1 ) {
        [heroVideo, ...allVideos] = nodes
      } else {
        allVideos = nodes
      }
    }
  }

  let bgColour = '' // red
  let sectionStyle = {}
  if ( archiveBgColour && archiveBgColour !== '' ) {
    bgColour = ''
    sectionStyle = { backgroundColor: archiveBgColour }
  }

  let textColour = '' // light-text
  if ( archiveFgText && archiveFgText === 'dark' ) {
    textColour = ''
  }

  return (
    <Layout pageTitle={`${archiveName} Videos`} bodyClass="archive-page">

      {pageInfo.currentPage === 1 && heroVideo &&
        <HeroCategoryVideoCard video={heroVideo} />
      }

      <PageSection
        title={archiveName}
        sectionClass={`${bgColour} ${textColour}`}
        sectionStyle={sectionStyle}
      >
        <div className="category-header">
          {archiveDescription &&
            <p>{archiveDescription}</p>
          }
          {archiveImage &&
            <p className="category-image">
              <img src={archiveImage} alt={`${archiveName} category logo`} />
            </p>
          }
        </div>
      </PageSection>

      <div className="content-wrapper">

      <PageSection
        title="All videos"
        sectionClass="" // light-text
      >
        {allVideos && allVideos.length > 0 ? (
          <>
          <div className="video-grid">
            {
              allVideos.map((video, index) => (
                <VideoCard video={video} key={index}></VideoCard>
              ))
            }
          </div>

          <ArchivePagination {...pageInfo} archivePath={archivePath} />
          </>
          ) : (
            <p>There are no other videos in this category</p>
          )
        }
      </PageSection>

      </div> {/* end of content-wrapper */}

    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $categoryDatabaseId: Int
  ) {
    allPosts: allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        videoUrl
        cardTitle
        cardImage
        cardImageSrcset
        featuredImage {
          node {
            sourceUrl
            srcSet
          }
        }
        title
        excerpt
        videoExcerpt
        slug
        id
        date
        featured
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    featuredPosts: allWpPost(
      limit: 5
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
        featured: { eq: true }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        cardTitle
        cardImage
        cardImageSrcset
        featuredImage {
          node {
            sourceUrl
            srcSet
          }
        }
        title
        excerpt
        videoExcerpt
        slug
        id
        date
        featured
      }
    }
  }
`

export default VideoCategoryArchive
